import React from "react";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import woldmap from '../../images/about/testmap.png';
//import map1 from '../../images/about/map 1.png';
import map2 from '../../images/about/map 2.png';
import bgSmoke from '../../images/background/smoke.png';

export default function Mission()  {

    let anniversary = new Date();
    let count =  new Date(anniversary);
    let value = Math.abs(count.getFullYear()) - 1962;
    console.log('value =>', value);
    return(
        <>
            <Header />
                {/* <div className="app-banner">
                    <div className="container aboutus">
                        <h1>About us</h1>
                    </div>
                </div> */}
                <div className="section-full bg-gray content-inner position-relative" 
                    style={{ 
                        backgroundImage:`url(${bgSmoke})`, 
                        paddingTop:'80px',
                        paddingBottom:'50px',
                        backgroundSize:'cover'
                    }}>
                        
                    <div className="container msv-container">
                        <h1 style={{fontWeight:'800', fontFamily:'Poppins', marginTop:'30px'}}>About us</h1>
                        <div className="row world-map-hotwork">
                            <div className="col">
                                <img src={woldmap} alt="" />
                            </div>
                        </div>
                        <div className="row aboutUs">
                            <div className="woldwide"><strong>WORLD WIDE AVAILABILITY</strong></div>
                            <div className="twenty-four-seven"><span>24/7, 365 DAYS A YEAR</span></div>
                        </div>
                        <div className="branches">
                            <div className="head-office">
                                <b>HEAD OFFICE:</b>
                                <span>Egnach, Switzerland</span>
                            </div>
                            <div className="north-america-group">
                                <span>Chicago, USA</span>
                                <span>Orlando, USA</span>
                                <span>Houston, USA</span>
                                <span>Cuenavaca, Mexico</span> 
                            </div>
                            <div className="europe-group">
                                <span>Bergen, Norway</span> 
                                <span>Berlin, Germany</span> 
                                <span>Kindberg, Austria</span> 
                                <span>Milan, Italy</span> 
                                <span>Istanbul, Turkey</span> 
                                <span>Nazare, Portugal</span>
                                <span>Cairo, Egypt</span>
                            </div>
                            <div className="asia-group">
                                <span>Busan, South Korea</span>
                                <span>Osaka, Japan</span>
                                <span>Bangkok, Thailand</span>
                                <span>Baroda, India</span>
                                <span>Cebu, The Philippines</span>
                                <span>Jakarta, Indonesia</span>
                                <span>Noumea, New Caledonia</span>
                            </div>
                        </div>
                        <div className="about-content" style={{marginTop: '35px'}}>
                            <strong style={{fontSize:'18px'}}>About us:</strong>
                            <p>
                                Celebrating its <strong>{value}th</strong> anniversary, <b>Hotwork International Group</b> has a rich history that dates back to 1962 when Hotwork Ltd. was founded in England. Over the years, we have nurtured strong customer relationships, earning their trust and loyalty. Our diverse expertise has allowed us to tackle a wide range of common and specialized challenges presented by our clients, strengthening our resilience through continuous adaptations to ever-changing markets. 
                                Hotwork International has evolved into a dynamic group of companies over the years while remaining deeply rooted in its heritage and history. It all began as <b>RHINO Heat Treatment Services Lda</b> in Portugal, and in 2022, the company seamlessly integrated with Hotwork International, forming a new entity known as <b>Hotwork Heat Treatment & Specialized Services (HTS)</b>. This strategic move has significantly expanded our global reach and capabilities. 
                                With a client-centered philosophy ingrained in our company's core values, we remain committed to transformation and growth amidst global challenges. Our employees and partners hold a special place in our journey, and we prioritize their development and alignment with our values. Our team consists of 350 professionals, including seasoned senior engineers with over 35 years of experience, enabling us to maintain our position as an innovative and market-leading company. 
                                We take pride in our global presence, operating in more than 65 countries worldwide, and we strive to further expand our reach. Our unwavering dedication to our founding purpose ensures that we stay true to our vision as we continue to evolve and positively impact the industries, we operate in. 
                                Our worldwide presence empowers us to offer a diverse range of services, and we pride ourselves on our ability to swiftly respond to any situation, with the possibility of reaching any location within 48 hours. This unparalleled responsiveness allows us to deliver effective solutions to virtually any challenge. 
                                By consistently meeting the needs and requirements of clients across different parts of the world, we demonstrate our professionalism and unwavering commitment to global excellence. Our dedication to providing top-notch services on a global scale solidifies our position as a reliable and trusted partner in the industry. 
                            </p>
                        </div>
                        <div className="historical-milestone" style={{marginTop: '40px'}}>
                            <h3 className="text-center" style={{fontWeight:'bold'}}>HISTORICAL MILESTONES</h3>
                            <div className="historical-year-history">
                                <div className="fist-historical-year">
                                    <ul>
                                        <li>
                                            <b>1962</b><br/>
                                            <p>Hotwork Ltd. started in England</p>
                                        </li>
                                        <li>
                                            <b>1986</b><br/>
                                            <p>Hotwork Germany waspurchased by 
                                               Jorg Koesterand renamed it to Hotwork Koester. 
                                               Since then, it hasbecome a family business.</p>
                                        </li>
                                        <li>
                                            <b>1992</b><br/>
                                            <p>Hotwork Koester supplies Burner and Combustion System to the Glass Industry</p>
                                        </li>
                                        <li>
                                            <b>1996</b><br/>
                                            <p>Founding HAL India</p>
                                        </li>

                                    </ul>
                                </div>
                                <div className="second-historical-year">
                                    <ul>
                                        <li>
                                            <b>1994</b><br/>
                                            <p>Hotwork International Asia(Cebu, Philippines)</p>
                                        </li>
                                        <li>
                                            <b>2000</b><br/>
                                            <p>Hotwork International China (Shenzhen)</p>
                                        </li>
                                        <li>
                                            <b>2004</b><br/>
                                            <p>Hotwork International moved its new head office in Switzerland</p>
                                        </li>
                                        <li>
                                            <b>2009</b><br/>
                                            <p>Hotwork International continues grow with the second generation</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="third-historical-year">
                                    <ul>
                                        <li>
                                            <b>2015</b><br/>
                                            <p>Founding of Heat Up Latin America in Mexico and Hotwork-XIX in Shanghai, China</p>
                                        </li>
                                        <li>
                                            <b>2016</b><br/>
                                            <p>Hotwork International Indonesia (Jakarta)</p>
                                        </li>
                                        <li>
                                            <b>2018</b><br/>
                                            <p>Founding of International Refractory Lining Services Philippines (IRSP) in the Philippines</p>
                                        </li>
                                        <li>
                                            <b>2019</b><br/>
                                            <p>Partnering wiht LINC in New Caledonia</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="fourth-historical-year">
                                    <ul>
                                        <li>
                                            <b>2020</b><br/>
                                            <p>Founding HAG in Sydney, Australia</p>
                                        </li>
                                        <li>
                                            <b>2021</b><br/>
                                            <p>Founding of Total Project Management(TPM) New Office IRSP northern Philippines</p>
                                        </li>
                                        <li>
                                            <b>2022</b><br/>
                                            <p>Integration of Hotwork Heat Treatment and Specialized Services(HTS)</p>
                                        </li>
                                        <li>
                                            <b>2023</b><br/>
                                            <p>Hotwork International welcomes HAL & HAG to the international group</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="fotwork-foundation">
                            <h3 style={{fontWeight:'bold'}}>HOTWORK FOUNDATION</h3>
                            <div id="target">
                                <p></p>
                            </div>
                        </div> */}
                    </div>
                </div> 

            <Footer />
        </>
        
    )
}

// export default function Mission()  {
//     return(
//         <>
//             <Header />
//                 <div className="app-banner" style={{ backgroundImage: `url(${require('../../images/gallery/rhts/pic9.jpg')})` }}>
//                     <div className="container aboutus">
//                         <h1>ABOUT US</h1>
//                     </div>
//                 </div>

//                 <div className="section-full bg-gray content-inner position-relative">
//                     <div className="container msv-container">
//                         <div className="row world-map-hotwork">
//                             <div className="col">
//                             <h1>HISTORICAL MILESTONES</h1>

//                                 <div className="row">
//                                     <div className="col">
//                                     <p style={{ background: 'red',color: 'white',borderRadius: '5px',padding: '2px', textAlign: 'center', width: '190px' }}>24/7, 365 DAYS A YEAR</p>
//                                     <p>
//                                         <b>Head Office:</b><br />
//                                         Egnach / Switzerland  <br /><br />
//                                         <b>Worldwide Availability:</b> <br />
//                                         Cuernavaca / Mexico <br />
//                                         Shanghai / China <br />
//                                         Baroda / India <br />
//                                         Cebe / The Philippines  <br />
//                                         Jakarta / Indonesia <br />
//                                         Osaka / Japan <br />
//                                         Noumea / New Caledonia <br />
//                                         Busan / South Korea <br />
//                                         Bangkok / Thailand <br />
//                                         Nazare / Portugal <br />
//                                         Bergen / Norway <br />
//                                         Hannover / Germany <br />
//                                         Doha / Qatar <br />
//                                     </p>
//                                     </div>
//                                     <div className="col mt-5">
//                                         <p>
//                                             <b>Representations:</b> <br />
//                                             Istanbul/Turkey <br />
//                                             Dubai/UAE <br />
//                                             Cairo/Egypt <br />
//                                             Orlando/USA <br />
//                                             Bogota/Columbia <br />
//                                             Melbourne/Australia
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col">
//                                 <img src={map1} alt="" />
//                             </div>
//                         </div>
//                         <div className="row aboutUs">
//                             <div className="col-md-6">
//                             <p style={{ textAlign: 'justify'}}>
//                             <b>About us:</b> <br />
//                             Hotwork International Group has celebrated its 55th anniversary last 2017. Although it was in 1986 that Hotwork International became fully family owned, we can trace our roots to 
//                             the foundation of the Hotwork Ltd. in England, year 1962.
//                             We have built the company through deep and lasting customer relationships with the type of trust that can only come over 
//                             time. Our customers have known us, for our skills and 
//                             engaged us with huge variety of common or special issues or 
//                             daily problems for us to solve.
//                             We have survived for many years through constant and continuous transformations, especially in adapting to the most 
//                             unpredictable situations in the markets we operate with.
//                             The philosophy of having the clients at the heart of everything 
//                             we do had become an important principle within our company. 
//                             Based on this, it is critical for us that we continue to transform 
//                             amid global challenges and changes. For this to be possible, 
//                             we give high regard to our employees and the people we work 
//                             with.
//                             </p>
//                             </div>
//                             <div className="col-md-6 motto">
//                             <p style={{ textAlign: 'justify'}}>
//                             <br />
//                             It is our priority to invest in our people with willingness to 
//                             grow and be selective to those who share our philosophy for 
//                             commitment, in order for us not to lose sight of the purpose 
//                             we started with.
//                             At the present, we are an innovative and market leading com- pany with a team of 350 professionals backed up by senior 
//                             engineers with over 35 years of experience.
//                             As we continue to grow and develop our reach globally, we 
//                             are proud to be in operations in over 65 countries worldwide.
//                             </p>
//                             <h2>“Your Service and Solution provider”</h2>
//                             </div>
//                         </div>
//                     </div>
//                 </div> 

//                 <div className="section-full bg-white content-inner position-relative">
//                     <div className="container msv-container">
//                         <div className="row world-map-hotwork">
//                             <div className="col-md-8">
//                                 <img src={map2} alt="" />
//                                 <p>
//                                 <b>Hotwork International</b> has become a group of companies over the past 
//                                 years, that will never lose sight of its heritage and history. <br />
//                                 This company was founded as RHINO heat treatmeat services, Lda in 
//                                 Portugal. In 2022 The company has been integrated with <b>Hotwork International as a new company named Hotwork Heat treatment 
//                                 & Specialized Services (HTS)</b> widening our field of work globally. <br />
//                                 We have the ability to perform several services worldwide and the 
//                                 possibility to reach anywhere with in 48 hours providing solutions to 
//                                 almost any kind of situation. <br />
//                                 Being able to satisfy the needs and the requirements of all clients in 
//                                 different part of the world, shows our professionalism and global
//                                 availability.

//                                 </p>
//                             </div>
//                             <div className="col-md-4">
//                                 <br />
//                                 <h1>WORLD WIDE AVAILABILITY</h1>
//                                 <div className="row">
//                                     <div className="col yearList pull-right">
//                                         <ul>
//                                             <li><h3>1960</h3>Hotwork Ltd. started in England</li>
//                                             <li><h3>1986</h3>Hotwork Germany waspurchased by Jorg Koesterand renamed it to Hotwork Koester. Since then, it hasbecome a family business.</li>
//                                             <li><h3>1992</h3>Hotwork Koester supplies Burner and Combustion System to the Glass Industry</li>
//                                             <li><h3>1994</h3>Hotwork International Asia(Cebu, Philippines)</li>
//                                             <li><h3>2000</h3>Hotwork International China (Shenzhen)</li>
//                                             <li><h3>2004</h3>Hotwork International moved its new head office in Switzerland</li>
//                                             <li><h3>2009</h3>Hotwork International continues to grow with the second generation</li>
//                                         {/* </ul>
//                                     </div>
//                                     <div className="col yearList">
//                                         <ul> */}
//                                             <li><h3>2010</h3>Hotwork International India (Baroda, Gujarat) leadership under Benjamin</li>
//                                             <li><h3>2015</h3>Founding of Heat Up Latin America in Mexico and Hotwork-XIX in Shanghai, China</li>
//                                             <li><h3>2016</h3>Hotwork International Indonesia (Jakarta)</li>
//                                             <li><h3>2018</h3>Founding of International Refractory Lining Services Philippines (IRSP) in the Philippines</li>
//                                             <li><h3>2019</h3>Partnering wiht LINC in New Caledonia</li>
//                                             <li><h3>2021</h3>Founding of Total Project Management(TPM) New Office IRSP northern Philippines</li>
//                                             <li><h3>2022</h3>Integration of Hotwork Heat Treatment and Specialized Services(HHTSS)</li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>  
//             <Footer />
//         </>
        
//     )
// }

function ValueList({children}) {
    return(
        <div className='p-1 text-muted d-flex'>
            <img className='checkImg mr-2' src={require('../../images/icon/icons8-checkmark-50.png')} />&nbsp;
            {children} 
        </div>
    );
}

const values = [
    'The staff strives for work effectiveness and are dedicated on keeping up our safety and integrity',
    'Sense of Belonging',
    'Integrity among the members that conform it.',
    'The client our greatest strength.',
    'Commitment to quality and technology to maintain a competitive advantage'
]