import React from 'react';
// import emailjs from 'emailjs-com';


const Footer10 = ()=>{
	// const form = useRef();
	// const sendEmail = (e) => {
	// 	e.preventDefault();

	// 	//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
	// 	emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
	// 	  .then((result) => {
	// 		  console.log(result.text);
	// 	  }, (error) => {
	// 		  console.log(error.text);
	// 	  });
	// 	  e.target.reset()
	// };
    return (
        <>
            <footer className="site-footer text-white">  
                <div className="footer-bottom" style={{ backgroundColor: '#76777A' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center"> 
                              <span>Copyright © {new Date().getFullYear()} Hotwork Heat Treatment and Specialized Services</span> 
                            </div>
                        </div>
                    </div>
                </div>
            </footer>                
        </>
    )
}
export default Footer10;