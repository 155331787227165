import React from "react";
import { useState, useEffect } from "react";
import Next from "../images/background/nextarrow.png";
import Prev from "../images/background/prevarrow.png";

export const Slider = ({ slides }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 1440px)").matches
    );
    console.log(matches);
    useEffect(() => {
        window
        .matchMedia("(min-width: 1440px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    const slideStyles = {
        height: "75.8rem",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage:`url(${slides[currentIndex].url})`,
        transition: "0.3s"
    }

    const leftArrowStyles = {
        position: "absolute",
        top: "30%",
        transform: "translate(0, -50%)",
        left: "50px",
        color: "#fff",
        zIndex: 1,
        cursor: "pointer",
    }

    const rightArrowStyles = {
        position: "absolute",
        top: "30%",
        transform: "translate(0, -50%)",
        right: "50px",
        color: "#fff",
        zIndex: 1,
        cursor: "pointer"
    }

    const dotsContainerStyles = {
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        width: "100%",
        fontSize:"30px",
        top:"53%",
        color: "#8f8b8b"
    }

    const dotsStyles = {
        margin: "0 3px",
    }

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }

    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    }

    return(
        <>
            <div style={{position: "absolute", top: "5.3rem", width: "100%"}}>
                <div style={leftArrowStyles} onClick={goToPrevious}>
                    <img src={Prev} alt="" />
                </div>
                {/* <div style={rightArrowStyles} onClick={goToNext}>&#129170;</div> */}
                <div style={rightArrowStyles} onClick={goToNext}>
                    <img src={Next} alt="" />
                </div>
                <div className="inner-slider" style={slideStyles}></div>
                <div className="dots-container" style={dotsContainerStyles}>
                    {
                        slides.map((slide, slideIndex) => {
                            return(
                                <div 
                                    className="dots-slider"
                                    key={slideIndex} 
                                    style={slideIndex === currentIndex ? {color: "#fff"} : {dotsStyles}} 
                                    onClick={() => goToSlide(slideIndex)}
                                >
                                    &#9679;
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}