import Header from '../layout/Header';
import Footer from '../layout/Footer';
import equipmentData from '../sections/equipmentData';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import '../../css/style.css';

const Equipment = () => {

  const { slug } = useParams();
  const [equipment, setEquipment] = useState(null);

  useEffect(() => {
    if (slug) {
      const equipment = equipmentData.find(item => item.slug === slug);
      setEquipment(equipment);
    }
  },[slug]);

  return (
    <>
      <Header />
      <div className="section-full bg-gray content-inner position-relative">
        <div className="container equipment" style={{marginBottom: '7rem'}}>
        <h1>Equipment</h1>
        <div className='equipment-container'>
            <Lists />
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};


const Lists =(() => {
    let history = useHistory();
    return(
        <> 
            {
                equipmentData.map((el, key) => {
                    return(
                        <> 
                            <div className='box'>
                                <span></span><span></span><span></span><span></span>
                                <div className='ec-header'>
                                    <img src={el.images[0].src} style={{height: 80,width: 80, borderRadius: 40}}/>
                                    <h3 className='eqpt-ttl'>{el.title}</h3>
                                </div><hr />
                                <div className='ec-body'>
                                    <p style={{marginTop: '15px', textAlign: 'justify'}}>
                                        {el.descriptions.length > 100 ? el.descriptions.slice(0, 100) + '...' : el.descriptions}
                                    </p>
                                    <button className="btn btn-primary services-btn" onClick={() => {
                                        history.push("/equipment/equipmentLists?equipmentLists=" + el.id);
                                    }}>
                                        View More
                                    </button>
                                </div>
                            </div>
                        </>
                    );
                })
            }
        </>
    )
})

export default Equipment;