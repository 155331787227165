import { createRef, React } from 'react';

 export default {
    company:[
        {
            title: 'Brochure',
            category: 'company',
            image: require('../../images/gallery/rhts/hts-brochure-logo.svg'),
        },
        {
            title: 'Bolting Service',
            category: 'company',
            image: require('../../images/gallery/rhts/bolt-services.jpeg'),
        },
        {
            title: 'Pipe Cutting',
            category: 'company',
            image: require('../../images/gallery/rhts/pipe-services.jpeg'),
        },
        // {
        //     title: 'Reference',
        //     category: 'company',
        //     image: require('../../images/gallery/rhts/pic4.jpg'),
        // }
    ],
    
    // products:[
    //     {
    //         title: 'Advanced Burner Bracket – ABB',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Alumina and Aluminum',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Bubbler',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Cement and Mineral Processing',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Combustion Control – ENOx',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Combustion Technology',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Container Glass',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Dry Out and Heat Up Service',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Electric Melting/Boosting',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'ENOx Combustion Control',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Fiber Glass',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Flatflame Oxygen Burner – FOB',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Float Glass',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Float Glass Combustion',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Forehearth / Feeder Burner',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Furnace Draining',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Furnace Heat Up',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Gas Analysis',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Hot Repair and Ceramic Welding',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Hydrocarbon/Chemical/Refinery',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Iron and Steel',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Navigation Testing Terminal System',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Oxy-Fuel Combustion',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Oxy-Service',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },{
    //         title: 'Oxygen Probe',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Oxygen Sensor',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Plant Wide Automation',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Power Generation',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Pre Heating Technology',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Pre-heating of Oxygen & Fuel Gas',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Quadraflo Sweep Glass Melter Crown',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Quadraflo® Burner',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'R&D – Burner Development',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Regenerator Spray Coating',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Regulating Gas Burner – RGB',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Regulating Hot Air Burner',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Regulating Oil Burner – ROB',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Oil Combustion',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Regulating Oxygen Burner',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Remote Control Gas Burner',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Risk Analysis',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Sealing Plate',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Second Phase Firing',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Standard Burner Bracket – SBB',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Table Ware',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'TFT / LCD / Cover Glass – Float',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'TFT / LCD / Cover Glass – Overflow',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Thermal Regenerator Cleaning',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Throughport Burner Gas/Oil',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     },
    //     {
    //         title: 'Zero Port Boosting',
    //         category: 'products',
    //         image: require('../../images/gallery/rhts/pic4.jpg'),
    //     }

    // ]
 };