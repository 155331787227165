import React, {useEffect, useRef, useState} from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import {useLocation, Redirect } from "react-router-dom";
import  equipmentData from '../../sections/equipmentData';
import img1 from '../../../images/gallery/rhts/equipment-2.jpg';
import img2 from '../../../images/gallery/rhts/equipment-1.jpg';
import img3 from '../../../images/gallery/rhts/pic4.png';
import img4 from '../../../images/gallery/rhts/HVO3600.png';
import Check from '../../../images/gallery/rhts/check.png';
import ".../../../src/css/style.css";
import bgSmoke from "../../../images/background/smoke.png";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Equipment(){
    const query = useQuery();
    const categoryId = query.get('equipmentLists');
    useEffect(() => {
        if (query.get('equipmentLists')) {
            //const id = query.get('services');
        }
    }, [query]);
    //console.log(equipmentData);
    return(
        <>
        <Header />
            <div className="section-full bg-gray content-inner position-relative"
                style={{ 
                    backgroundImage:`url(${bgSmoke})`, 
                    paddingBottom:'50px',
                    backgroundSize:'cover'
                }}>
                <div className="container equipment">
                    {
                        //console.log(categoryId)
                        categoryId === 'gas-fired-burner-hv3000' ? <GasFiredBurnerHV3000 /> :
                        categoryId === 'gas-fired-burner-hv1500' ? <GasFiredBurnerHV1500 /> :
                        categoryId === 'six-way-console-control-unit' ? <SixWayConsole /> :
                        categoryId === 'gas-fired-burner-3600' ? <GasFiredBurnerHV3600 /> :
                        <Redirect to={`/equipment`} />
                    }   
                </div>
            </div>
        <Footer />
        </>
    )
}

const GasFiredBurnerHV3000 = (() => {
    return(
        <>
            <h3>{equipmentData[0].title}</h3>
            <div className='services-image-container' style={{ 
                backgroundImage:`url(${img1})`
            }}></div>
            <p style={{textAlign:'justify', marginTop: '30px'}}>{equipmentData[0].descriptions}</p>
            <div className='services-lists'>
                <div className='services-lists-container' style={{marginTop: '30px'}}>
                    <div className='list' style={{width:'100%', display: 'flex', justifyContent:'space-between'}}>
                        {
                            equipmentData[0].lists.map((el, key) => {
                                if (key === 0) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                                if (key === 1) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                                if (key === 2) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
});

const GasFiredBurnerHV1500 = (() => {
    return(
        <>
            <h3>{equipmentData[1].title}</h3>
            <div className='services-image-container' style={{ 
                backgroundImage:`url(${img2})`
            }}></div>
            <p style={{textAlign:'justify', marginTop: '30px'}}>{equipmentData[1].descriptions}</p>
            <div className='services-lists'>
                <div className='services-lists-container' style={{marginTop: '30px'}}>
                    <div className='list' style={{width:'100%', display: 'flex', justifyContent:'space-between'}}>
                        {
                            equipmentData[1].lists.map((el, key) => {
                                if (key === 0) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                                if (key === 1) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                                if (key === 2) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
});

const SixWayConsole = (() => {
    return(
        <>
            <h3>{equipmentData[2].title}</h3>
            <div className='services-image-container' style={{ 
                backgroundImage:`url(${img3})`, backgroundSize: 'contain',
                backgroundPosition: 'center'
            }}></div>
            <p style={{textAlign:'justify', marginTop: '30px'}}>{equipmentData[2].descriptions}</p>
            <div className='services-lists'>
                <div className='services-lists-container' 
                style={{marginTop: '30px'}}>
                    <div className='list' style={{width:'100%', display: 'flex', justifyContent:'space-between'}}>
                        {
                            equipmentData[2].lists.map((el, key) => {
                                if (key === 0) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{padding:'20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
});

const GasFiredBurnerHV3600 = (() => {
    return(
        <>
            <h3>{equipmentData[3].title}</h3>
            <div className='services-image-container' style={{ 
                backgroundImage:`url(${img4})`
            }}></div>
            <p style={{textAlign:'justify', marginTop: '30px'}}>{equipmentData[3].descriptions}</p>
            <div className='services-lists'>
                <div className='services-lists-container' style={{marginTop: '30px'}}>
                    <div className='list' style={{width:'100%', display: 'flex', justifyContent:'space-between'}}>
                        {
                            equipmentData[3].lists.map((el, key) => {
                                if (key === 0) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                                if (key === 1) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                                if (key === 2) {
                                    return(
                                        
                                        <>
                                        <div className='inner-desc' style={{width:'350px', padding: '20px 25px'}}>
                                            <h6>{el.listsTitle}</h6>
                                            {
                                                 el.listsDescription.map((element) => {
                                                    return(
                                                        <>
                                                            <div className="list-body">
                                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>{element.key}, {element.value}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })          
                                            }
                                        </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
});