import React from 'react';
import "react-animated-slider/build/horizontal.css";
import "../../css/slider-animations.css";
import "../../css/homeslider.css";
import { Slider } from '../../components/ImageSlider';

const slides = [
  {
    url: require("../../images/background/slide1.jpeg")
  },
  {
    url: require("../../images/background/slide2.jpeg")
  },
  {
    url: require("../../images/background/slide3.jpeg")
  }
];

const HomeSlider2 = ({resultRef}) => {

  const goServiceSection = (e) => {
    window.scroll({
      top: resultRef.current.offsetTop - 60,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <div 
        className='hero-sectionsss' 
        //style={{ backgroundImage: `url(${require('../../images/background/pavel-chernonogov2.jpg')})`}}
      >
        <Slider slides={slides} />
        <div />
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className='context'>
                <h1>Hotwork Heat Treatment and Specialized Services</h1>
                <p style={{ maxWidth: '90%', width: '100%' }}>
                  These are the services we offer, Postweld Heat Treatment, Dehydrogenation Heat Treatment, Pre-Heating, Hydrogen Bake Out, Hardness Testing ...
                </p>
                <button onClick={goServiceSection}>Get Started</button>
              </div>
            </div>
          </div>
        </div>
        <div className='app-label container'>
          <p className='mb-0'>
            <strong>Hotwork Heat Treatment & Specialized Services</strong>, <i>a member of Hotwork International Group</i>
          </p>
        </div>
      </div>
    </>
  );
}

// function HomeSlider2({ data, btnText, contentCls, subTitleCls }) {
//   const sliderContent = data || content;

//   return (
//     <>
//       <Slider className="slider-wrapper">
//       {sliderContent.map((item, index) => (
//           <div
//             key={index}
//             className="slider-content"
//             style={{ background: `url('${item.image}') no-repeat center center` }}
//           >
//             <div className={`inner ${contentCls}`}>
//             <div>

//               {item.subTitle && 
//                 <p className={`subTitle1 ${subTitleCls}`}>{item.subTitle}</p> 
//               }

//               <h1>{item.title}</h1>
//               <p>{item.description}</p>

//               {item.subTitle2 && 
//                 <p>{item.subTitle2}</p> 
//               }
//               <button className="text-primary">{btnText}</button>
//             </div>
//             </div>
//           </div>
//         ))}
//       </Slider>

//       </>
//   );
// }

export default HomeSlider2;

const content = [
  { 
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    image: require("../../images/main-slider/slide11.jpg"),
    subTitle: "Luan Gjokaj",
    subTitle2: "https://i.imgur.com/JSW6mEk.png"
  },
  { 
      title: "Vulputate Mollis Ultricies Fermentum Parturient",
      description:
          "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
      image: require("../../images/main-slider/slide11.jpg"),
      subTitle: "Luan Gjokaj",
      subTitle2: "https://i.imgur.com/JSW6mEk.png"
  },

];