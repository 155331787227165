import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { CategoryLists } from '../../../sections/servicesData';
import bolting from '../../../../images/gallery/rhts/bolting-services.jpg';
import pipe from '../../../../images/gallery/rhts/pipe-cutting-beveling.jpg';
import machineservice from '../../../../images/gallery/rhts/machine-service.png';
import equipmengt from '../../../../images/gallery/rhts/bolt-equipment.png';

const sizesData = [
     {
        torque: "232-2328",
        nutsize: "19-55"
     },
     {
        torque: "241-2414",
        nutsize: "60"
     },
     {
        torque: "585-2510",
        nutsize: "34-36"
     },
     {
        torque: "585-5021",
        nutsize: "41"
     },
     {
        torque: "585-5858",
        nutsize: "46-65"
     },
     {
        torque: "647-6474",
        nutsize: "70-80"
     },
     {
        torque: "1049-10491",
        nutsize: "41-95"
     },
     {
        torque: "1177-11774",
        nutsize: "100-105"
     },
     {
        torque: "1852-18521",
        nutsize: "50-117"
     },
     {
        torque: "4188-41882",
        nutsize: "110-155"
     },
     {
        torque: "4459-44593",
        nutsize: "160-175"
     },
]
    
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function MechanicalLists(){
    const query = useQuery();
    const categoryId = query.get('service');

    useEffect(() => {
        if (query.get('service')) {

        }
    }, [query]);

    return(
        <>
            {
                categoryId === 'bolt-services' ? <BoltServices /> :
                categoryId === 'pipe-cutting' ? <PipeCutting /> :
                <Redirect to={`/services`} />
            }
        </>
    )
}

const BoltServices = (() => {
    return(
        <>
            {
                CategoryLists[5].list.map((el, key) => {
                    if (key === 0) {
                        return(
                            <div className='container services' >
                                <h3>Bolt Services</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${bolting})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                                <p style={{marginTop: "30px", fontWeight: "bold"}}>Used equipment:</p>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <img src={equipmengt} alt="equipment" />
                                </div>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <p>Pneumatic Driven Hydraulic Torque Wrench Pumps</p>
                                    <div style={{width: "5%"}}></div>
                                    <p>Low Profile Hexagon Hydraulic Torque Wrenches</p>
                                </div>
                                <p style={{marginTop: "50px", fontWeight: "bold"}}>Coverd Sizes:</p>
                                <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
                                    <table style={{maxWidth: "650px", textAlign: "center",}}>
                                        <thead style={{backgroundColor: "#ee1f23"}}>
                                            <th style={{border: "solid 2px #000", color: "white"}}>Torque(N.M.)</th>
                                            <th style={{border: "solid 2px #000", color: "white"}}>Nut Size(mm)</th>
                                        </thead>
                                        <tbody>
                                            {sizesData.map((el) => {
                                                    return(
                                                        <tr>
                                                            <td style={{border: "solid 1px", padding: "0"}}>{el.torque}</td>
                                                            <td style={{border: "solid 1px", padding: "0"}}>{el.nutsize}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const PipeCutting = (() => {
    return(
        <>
            {
                CategoryLists[5].list.map((el, key) => {
                    if (key === 1) {
                        return(
                            <div className='container services' style={{height: "1800px"}}>
                                <h3>Pipe Cutting and Beveling</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${pipe})`}}>
                                    
                                </div>
                                <p style={{fontWeight: "bold", marginTop: '50px'}}>Machining field services</p>
                                <p style={{textAlign: 'justify', marginTop: '10px'}}>{el.description}</p>
                                <div className='services-image-container' style={{ backgroundImage:`url(${machineservice})`}}>
                                    
                                </div>
                                <p style={{marginTop: '30px'}}>Maintaining high-quality standards is a top priority for us. Our dedicated team diligently developed and
                                    implemented procedures to ensure superior results. You can trust in our expertise and commitment to delivering outstanding outcomes.
                                    Choose Hotwork for reliable field machining services that minimize downtime and maximize efficiency.
                                    Contact us today to discuss your specific requirements and experience the expertise and professionalism of
                                    our team.
                                </p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})





