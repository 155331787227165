import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.hotwork.ag' : 'http://localhost/hotware';

export default function Contact() {
    const [width, setWidth] = React.useState(null);
    const[custName, setCustName] = useState('');
    const[email, setEmail] = useState('');
    const[message, setMessage] = useState('');


    React.useEffect(() => {
        function resizeWindow () {
            const container = document.querySelector('.container');
            setWidth(container.offsetWidth / 2);
        }

        resizeWindow();

        window.addEventListener('resize', resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);

    const [status, setStatus] = useState('Submit');
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setStatus('Sending');

        const {custName, email, message} = e.target.elements;


        let details = {
            name: custName.value,
            email: email.value,
            message: message.value
        }

        const formData = new FormData();
        
        for(let [key, value] of Object.entries(details)) {
            formData.append(key,value);
        }

        const res = await fetch(BASE_URL + '/api/hotworkhts/send_mail', {
            method: "POST",
            body: formData,
        });

        setStatus("Submit");
        
        let data = await res.json();
        resetForm();
        alert(data);
    }

    const resetForm = async() => {
        setCustName('');
        setEmail('');
        setMessage('');
    }

    return (
        <div className="bg-white section-full content-inner" id="contact">
            <div className="container">
                <div className="section-head">
                    <h2 className="title text-center">Contact Us</h2>
                </div>
                    <div className="row contactRow"> 
                        <div className="col-md-6 contact-form-container">
                            <form className="contact-form" onSubmit={handleSubmitForm}>
                                <div className="form-group">
                                    <label style={{fontWeight: 400}}>Name</label>
                                    <input placeholder="Name" className="form-control" id='custName' value={custName} onChange={ event => setCustName(event.target.value) } />
                                </div>
                                <div className="form-group">
                                    <label style={{fontWeight: 400}}>Email Address</label>
                                    <input type="email" placeholder="Email Address" className="form-control" id='email' value={email} onChange={ event => setEmail(event.target.value) } />
                                </div>
                                <div className="form-group">
                                    <label style={{fontWeight: 400}}>Message</label>
                                    <textarea placeholder="Message" id="message" value={message} cols="30" rows="4" className="form-control" onChange={ event => setMessage(event.target.value) }></textarea>
                                </div>
                                <button type='submit' className="btn btn-primary contact-form-button">Send</button>

                                <div className="social_list">
                                    <a href="https://goo.gl/maps/tR4WG4k2aKxZGB3T9" target="_blank">
                                        <span className="material-symbols-outlined">
                                            location_on
                                        </span>
                                        <span>R. Comércio da Industria, 2445-768 Martingança, Portugal</span>
                                    </a>
                                    <a href="tel:+351932272147">
                                        <span className="material-symbols-outlined">
                                            call
                                        </span>
                                        <span>+351932272147</span>
                                    </a>
                                    <a href="mailto:dany.reyes@hotworkhts.com">
                                        <span className="material-symbols-outlined"> 
                                            mail
                                        </span>
                                        <span className="text">contact@hotworkhts.com</span>
                                    </a>
                                </div>

                            </form>
                        </div>
                        <div className='col-md-6'>
                            <div className="contact-info">
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d767.5608835832619!2d-8.946304070639037!3d39.689228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd22096d33e0e36d%3A0x793fa011b8c90d0d!2sR.%20Com%C3%A9rcio%20da%20Industria%2C%20Martingan%C3%A7a%2C%20Portugal!5e0!3m2!1sen!2sph!4v1683685209892!5m2!1sen!2sph" 
                                    width="100%" 
                                    height="100%" 
                                    style={{border: 0}} 
                                    allowFullScreen="" 
                                    loading="lazy" 
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="contact_us_info">
                                <div className="name">
                                    <span>Dany Reyes</span>
                                    <span>Operations Manager - RHTS,Lda.</span>
                                </div>
                                <div className="tel">
                                    <span>
                                        <span class="material-symbols-outlined">
                                            call
                                        </span>
                                        <span>+351932272147 </span>
                                    </span>
                                    <span>
                                        <span class="material-symbols-outlined">
                                            call
                                        </span>
                                        <span>+351932272147 </span>
                                    </span>
                                </div>
                                <div className="email">
                                    <span>
                                        <span class="material-symbols-outlined">
                                            mail
                                        </span>
                                        <span className="text">d.reyes@ihts.pt</span>
                                    </span>
                                    <span>
                                        <span class="material-symbols-outlined">
                                            mail
                                        </span>
                                        <span className="text">d.reyes@ihts.pt</span>
                                    </span>
                                </div>
                                <div className="location">
                                    <span>
                                        <span class="material-symbols-outlined">
                                            location_on
                                        </span>
                                        <span>R. Luís de Camões, Samora Correia, Portugal</span>
                                    </span>
                                </div>

                                <ul className="list-inline m-a0">
                                    <li><Link to="#" className="site-button btnhover21 facebook circle "><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 google-plus circle "><i className="fa fa-google-plus"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 linkedin circle "><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 instagram circle "><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 twitter circle "><i className="fa fa-twitter"></i></Link></li>
                                </ul>
                            </div>
                        
                        </div>  
                        <div className="col-md-6">
                            <div>
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6294829150715!2d-8.87940598437559!3d38.93244545248214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1921a8fb03f02d%3A0x2dc7167b62382555!2sR.%20Lu%C3%ADs%20de%20Cam%C3%B5es%2C%20Samora%20Correia%2C%20Portugal!5e0!3m2!1sen!2sch!4v1661435911501!5m2!1sen!2sch" 
                                width="600" 
                                height="450" 
                                style={{border: 0}} 
                                allowFullscreen="" 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                            </div>
                            <div className='ContactForm'>
                                <div className='container'>
                                    <div className='row'>
                                    <div className='col-12 text-center'>
                                        <div className='contactForm'>
                                        <form id='contact-form'>
                                            <div className='row formRow'>
                                            <div className='col-6 mb-3'>
                                                <input
                                                type='text'
                                                name='name'
                                                className='form-control formInput'
                                                placeholder='Name'
                                                ></input>
                                            </div>
                                            <div className='col-6 mb-3'>
                                                <input
                                                type='email'
                                                name='email'
                                                className='form-control formInput'
                                                placeholder='Email address'
                                                ></input>
                                            </div>
                                            </div>
                                            <div className='row formRow'>
                                            <div className='col mb-3'>
                                                <input
                                                type='text'
                                                name='subject'
                                                className='form-control formInput'
                                                placeholder='Subject'
                                                ></input>
                                            </div>
                                            </div>
                                            <div className='row formRow'>
                                            <div className='col mb-3'>
                                                <textarea
                                                rows={3}
                                                name='message'
                                                className='form-control formInput'
                                                placeholder='Message'
                                                ></textarea>
                                            </div>
                                            </div>
                                            <button class="btn btn-primary" type='submit'>
                                            Submit
                                            </button>
                                        </form>
                                        </div>
                                    </div>
                                    <div class="col-"></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                                   
                    </div>
                
            </div>
        </div>
    );
}