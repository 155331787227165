import React, {useEffect, useRef, useState} from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { CategoryLists } from '../../sections/servicesData';
import { useHistory, useLocation } from "react-router-dom";
//import ".../../css/style.css";
import ".../../../src/css/style.css";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const CoatingCures = (() => {
    let history = useHistory();
    return(
        <>
            {
                CategoryLists.map((el, key) =>{
                    if (key === 2) {
                        return(
                            <>
                                <div className='container services' style={{height: '770px'}}>
                                    <h1>Coating Cures</h1>
                                    <div className='services-container'>
                                        {
                                            CategoryLists[2].list.map((el) =>{
                                                return(
                                                    <div className='box'>
                                                        <div className='sc-header'>
                                                            <img src={el.image} style={{height: 80,width: 80, borderRadius: 40}} />
                                                            <strong className='services-ttl'>{el.name}</strong>
                                                        </div><hr />
                                                        <div className='sc-body'>
                                                                <p>{el.description}</p>
                                                                <button className="btn btn-primary services-btn" 
                                                                onClick={() => {
                                                                    history.push("/theservice/service?service=" + el.id);
                                                                }}>
                                                                    View More
                                                                </button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                })
            }
        </>
    )
})