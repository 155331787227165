import React from 'react';
import Card from 'react-bootstrap/Card';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Pagination } from "swiper";
import { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import equipmentData from './equipmentData'
import { Link, useHistory } from 'react-router-dom';
import { NavItem } from 'react-bootstrap';

export default function Index() {
    const [equipment, setEquipment] = useState(null);
    const [showModal, setShowModal] = useState(false);
    let history = useHistory();
    const modalRef = useRef(null);
    const swiperItemRef = useRef(null);

    const onViewMore = (key) => {
        setShowModal(true);
        setEquipment(equipmentData[key])
        
    };
    const onClose = () => {
        setShowModal(false);
        setEquipment(null);
    };

    return (
    <div className='equipment section-full bg-gray content-inner' id='equipments'>
        <div className='container'>
            <div className='section-head'>
                <h2 className="title mt-5 text-center">Equipments</h2>
                    <p className='card-text'>At <strong>Hotwork Heat Treatment & Specialized Services</strong>,
                        we take immense pride in presenting our extensive array of professional equipment 
                        meticulously designed to streamline your workflow and fulfill your requirements. Our dedication 
                        to excellence drives us to craft custom-engineered solutions that empower your business to reach new heights of efficiency.
                    </p>
                <CSSTransition
                    in={showModal}
                    nodeRef={modalRef}
                    timeout={300}
                    classNames="equipment-modal"
                    unmountOnExit
                >
                    <EquipmentModal
                        equipment={equipment}
                        ref={modalRef}
                        onClose={onClose}
                    />
                </CSSTransition>
            </div>
            
            <Swiper
                //slidesPerView={1}
                spaceBetween={20}
                slidesPerGroup={1}
                loopFillGroupWithBlank={false}
                pagination={true}
                modules={[Pagination]}
                autoplay={{ delay: 500 }}
                className="equipmentSwiper"
                breakpoints={{
                    640: {
                        width: 640,
                        slidesPerView: 1,
                    },
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                    }}
                >
            
                {equipmentData && equipmentData.map((item, key) => (
                    <SwiperSlide className='swiperContent' key={key}>
                        <div className='colCard position-relative'>
                            <Card className='equipmentCard border-0'>
                                <Card.Img 
                                    variant="top" 
                                    src={item.images[0].src} 
                                />
                                    <Card.Body className='contentBody'>
                                        <h4 style={{ fontSize: '1.3em' }}>{item.title}</h4>
                                        <Card.Text>
                                            {item.descriptions.length > 150 ? item.descriptions.slice(0, 150) + '...' : item.descriptions}
                                        </Card.Text>
                                        <button className="btn btn-primary equipment-button mt-auto" 
                                            onClick={() => {
                                                history.push("/equipment/equipmentLists?equipmentLists=" + item.id);
                                            }}>View More</button>
                                        {/* <button className="btn btn-primary equipment-button mt-auto" 
                                            onClick={() => onViewMore(key)}
                                        >View More</button> */}
                                    </Card.Body>
                            </Card>
                        </div>
                    </SwiperSlide> 
                ))} 
            </Swiper>  
        </div>
    </div>
    );
}

const EquipmentModal = React.forwardRef(({equipment, onClose}, ref) => {
    const [smallWidth, setSmallWidth] = useState(null);

    useEffect(() => {
        const resizeWindow = () => {
            const { innerWidth } = window;
            setSmallWidth(innerWidth);
        };
        resizeWindow();
        window.addEventListener('resize', resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow);
        }
    }, []);
    return (
        <>
        {smallWidth > 750 && (
            <div className='overlay-equipment-modal' ref={ref}>
                <div className="equipment-modal-content container p-0">
                    <div className="d-flex w-100">
                        <div className='col-md-6 modal-details p-0'>
                            <div className='content-header'>
                                <h4 className='mb-0' >{equipment && equipment.title}</h4>
                            </div>
                            <div className='px-4 pb-4' style={{ margin: '20px' }}>
                                <div className='content-descriptions'>
                                    {equipment && equipment.descriptions}
                                </div>
                                <div className='content-spcifications mt-3'>
                                    {equipment && equipment.lists.map((list, key) => (
                                        <>
                                            <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '15px' }} key={key} >{list.listsTitle}</div>
                                            {list.listsDescription && list.listsDescription.map(item => (
                                                <EquipmentList>
                                                    {item.key ? item.key + ': ' +item.value : item.value}
                                                </EquipmentList>
                                            ))}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 modal-image position-relative p-0'>
                            <button className='close-btn' onClick={onClose}>&times;</button>
                            <Swiper
                            pagination={true}
                            modules={[Pagination]}
                            >
                                {equipment && equipment.images.map((image, key) => (
                                    <SwiperSlide key={key}>
                                        <img className='equipment-images' src={image.src} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        )}
        
        {smallWidth <= 750 && (
            <div className='overlay-equipment-modal' ref={ref}>
                <div className="equipment-modal-content container p-0">
                    <div className="d-flex w-100">
                        <div className='col-md-6 modal-details p-0'>
                        <button className='close-btn' onClick={onClose}>&times;</button>

                            <div className='content-header'>
                                <h4 className='mb-0' >{equipment && equipment.title}</h4>
                            </div>
                            <div className='px-4 pb-4'>
                                <div className='content-descriptions'>
                                    {equipment && equipment.descriptions}
                                </div>
                                <div className='content-spcifications mt-3'>
                                    {equipment && equipment.lists.map((list, key) => (
                                        <>
                                            <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '15px' }} key={key} >{list.listsTitle}</div>
                                            {list.listsDescription && list.listsDescription.map(item => (
                                                <EquipmentList>
                                                    {item.key ? item.key + ': ' +item.value : item.value}
                                                </EquipmentList>
                                            ))}
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className='modal-image position-relative p-0'>
                                <Swiper
                                pagination={true}
                                modules={[Pagination]}
                                >
                                    {equipment && equipment.images.map((image, key) => (
                                        <SwiperSlide key={key}>
                                            <img className='equipment-images' src={image.src} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
       </>
    );
});

function EquipmentList({ children }) {
    return (
        <div className='p-1'>
            <img className='checkImg  mr-2' src={require('../../images/icon/icons8-checkmark-50.png')} />&nbsp;
            {children} 
        </div>
    );
}

