import { createRef } from 'react';

export default [
    {
        id: 'gas-fired-burner-hv3000',
        title: 'Gas fired burner HV3000',
        slug: 'gas-fired-burner-set',
        descriptions: 'HV3000 Heat Up burners have a max. Capacity of 300 Nm3 / h of natural gas and max. 5,800 Nm3 / combustion air.Flame supervision by UV cells. In case of flame failure, the gas supply will be stopped within 2s by electro safety shut-off valves.',
        lists: [
            {
                listsTitle: 'Position 1 Combustion fan with burner control unit (BCU)',
                listsDescription: [
                    {
                        key: 'Current consumption',
                        value: '15 kW, direct starting'
                    },
                    {
                        key: 'voltage',
                        value: '440 V, 3 phases, ground, 63 A'
                    },
                    {
                        key: 'Dimensions',
                        value: '800 x 1200 (LxW), mounted on wheels'
                    },
                    {
                        key: 'Sonar',
                        value: '83 dba'
                    },
                    {
                        key: 'BCU',
                        value: 'UV flame supervision, PLC controlled, alarm'
                    },
                    {
                        key: 'Weight',
                        value: '280 kg'
                    },
                ]
            },
            {
                listsTitle: 'Position 2	Gas / fuel supply station',
                listsDescription: [
                    {
                        key: 'Safety components',
                        value: 'double gas safety shut-off, pilot, pressure switch'
                    },
                    {
                        key: 'Cooling / burner purge',
                        value: 'compressed air max. 1.5 bar, 5-10 Nm³ / h'
                    },
                    {
                        key: 'Max. Flow',
                        value: '300  Nm³ / h'
                    },
                    {
                        key: 'Max. Pressure',
                        value: 'at 4 bar max., internal pressure reducer at 0.5'
                    },
                    {
                        key: 'Weight',
                        value: '25 kg'
                    },
                    {
                        key: 'Couplings',
                        value: 'Camlock 2" / Air Cooling ¼"'
                    },
                ]
            },
            {
                listsTitle: 'Position 3	High speed burner, type HV3000 for gas',
                listsDescription: [
                    {
                        key: 'Safety components',
                        value: 'UV flame sensor, automatic ignition, pressure switch'
                    },
                    {
                        key: 'Power',
                        value: '300 Nm³/h natural gas (approximately 3MW)'
                    },
                    {
                        key: 'Nozzle',
                        value: 'High temperature steel (1250°C), optional nickel free'
                    },
                    {
                        key: 'Dimensions of the nozzle',
                        value: '200×100 mm (other dimensions on request)'
                    },
                    {
                        key: 'Air',
                        value: '250mm'
                    },
                    {
                        key: 'inlet Gas inlet',
                        value: '2” Camlock'
                    },
                    {
                        key: 'Weight',
                        value: '25kg'
                    },
                ]
            }
        ],
        images: [
            {
                src: require('../../images/gallery/rhts/equipment-2.jpg'),
                nodeRef: createRef(null)
            },
        ],
    },
    {
        id: 'gas-fired-burner-hv1500',
        title: 'Gas fired burner HV1500',
        slug: 'gas-fired-burner-set',
        descriptions: 'HV1500 Heat Up burners have a max. Capacity of 150 Nm3 / h of natural gas and max. 3,800 Nm3 / combustion air. Flame supervision by UV cells. In case of flame failure, the gas supply will be stopped within 2s by electro safety shut-off valves.',
        lists: [
            {
                listsTitle: 'Position 1 Combustion fan with burner control unit (BCU)',
                listsDescription: [
                    {
                        key: 'Current consumption',
                        value: '7.5 kW, direct starting'
                    },
                    {
                        key: 'voltage',
                        value: '440 V, 3 phases, ground, 32 A'
                    },
                    {
                        key: 'Dimensions',
                        value: '800 x 1000 (LxW), mounted on wheels'
                    },
                    {
                        key: 'Sonar',
                        value: '78 dba'
                    },
                    {
                        key: 'BCU',
                        value: 'UV flame supervision, PLC controlled, alarm'
                    },
                    {
                        key: 'Weight',
                        value: '240 kg'
                    },
                ]
            },
            {
                listsTitle: 'Position 2	Gas / fuel supply station',
                listsDescription: [
                    {
                        key: 'Safety components',
                        value: 'double gas safety shut-off, pilot, pressure switch'
                    },
                    {
                        key: 'Cooling / burner purge',
                        value: 'compressed air max. 1.5 bar, 5-10 Nm³ / h'
                    },
                    {
                        key: 'Max. Flow',
                        value: '150  Nm³ / h'
                    },
                    {
                        key: 'Max. Pressure',
                        value: 'at 4 bar max., internal pressure reducer at 0.5'
                    },
                    {
                        key: 'Weight',
                        value: '25 kg'
                    },
                    {
                        key: 'Couplings',
                        value: 'Camlock 1" / Air Cooling ¼"'
                    },
                ]
            },
            {
                listsTitle: 'Position 3	High speed burner, type HV3000 for gas',
                listsDescription: [
                    {
                        key: 'Safety components',
                        value: 'UV flame sensor, automatic ignition, pressure switch'
                    },
                    {
                        key: 'Power',
                        value: '150 Nm³/h natural gas (approximately 3MW)'
                    },
                    {
                        key: 'Nozzle',
                        value: 'High temperature steel (1250°C), optional nickel free'
                    },
                    {
                        key: 'Dimensions of the nozzle',
                        value: '100×100 mm (other dimensions on request)'
                    },
                    {
                        key: 'Air',
                        value: '150mm'
                    },
                    {
                        key: 'inlet Gas inlet',
                        value: '1” Camlock'
                    },
                    {
                        key: 'Weight',
                        value: '25kg'
                    },
                ]
            }
        ],
        images: [
            {
                src: require('../../images/gallery/rhts/equipment-1.jpg'),
                nodeRef: createRef(null)
            },
        ],
    },
    {
        id: 'six-way-console-control-unit',
        title: 'Six-way console control unit ',
        slug: 'Six-way-console',
        descriptions: 'Equipment for thermal treatments both electric and combustion, equipped with a digital control system to develop a uniform thermal process, we have furnaces to make in-house heat treatment for large vessel pressure pipes, etc. Six-way Console (Equipped with standard or wireless heat master) ',
        lists: [
            {
                listsDescription:[
                    {
                        value: 'Electrical rating: 50 kVA, '
                    },
                    {
                        value: 'Transformer: Air natural, Class H insulation'
                    },
                    {
                        value: 'Dimensions: 68cmWx70.5cmDx107cmH '
                    },
                    {
                        value: 'Weight: 312 kg s'
                    },
                    {
                        value: 'Casing: Carbon Steel, Stainless Steel, or Heavy Duty Polyethylene '
                    },
                    {
                        value: 'Primary Supply: 380 - 76A/415 - 70A/440 - 65A, 3 phase '
                    },
                    {
                        value: 'Output circuits: 6 pair at the rear, twist lock connectors '
                    },
                    {
                        value: ' Protection Overload: 3-phase MCCB. Over temperature sensor in windings – 3-phase MCCB with shunt trip. '
                    },
                    {
                        value: ' Supply cable: 5m primary provided, 16mm², 4-core, SY cable, (recessed angle location at rear). '
                    },
                    {
                        value: 'Mobility: 2- swivel wheels, 160mm dia., 2- fixed wheels, 160mm dia Lifting: Forklift or webbing slings under the casing. '
                    },
                    {
                        value: 'Auxiliary Sockets: 3 – sockets 110V ac, 5A (BS 4343) for instruments, etc. '
                    },
                ]
            }
        ],
        images: [
            {
                src: require('../../images/gallery/rhts/Six way console.png'),
                nodeRef: createRef(null)
            },
        ],
    },
    {
        id: 'gas-fired-burner-3600',
        title: 'Gas fired burner HV3600',
        slug: 'gas-fired-burner-set',
        descriptions: 'HVO3600 Heat Up burners have a max. Capacity of 190l/h Diesel or Light Oil (boosted 450l/h) max. 6.800 Nm3/ combustion air. Flames supervision by UV-cells. In case of flame failure, fuel supply will be stopped within less then 2 s by a double safety shut-off electro valves.',
        lists: [
            {
                listsTitle: 'Position 1 Combustion fan with burner control unit (BCU)',
                listsDescription: [
                    {
                        key: 'Current consumption',
                        value: '15 kW, direct starting'
                    },
                    {
                        key: 'voltage',
                        value: '440 V, 3 phases, ground, 63 A'
                    },
                    {
                        key: 'Dimensions',
                        value: '800 x 1200 (LxW), mounted on wheels'
                    },
                    {
                        key: 'Sonar',
                        value: '83 dba'
                    },
                    {
                        key: 'BCU',
                        value: 'UV flame supervision, PLC controlled, alarm'
                    },
                    {
                        key: 'Weight',
                        value: '375 kg'
                    },
                ]
            },
            {
                listsTitle: 'Position 2	Oil / Fuel Supply Station',
                listsDescription: [
                    {
                        key: 'Safety components',
                        value: 'double oil safety shut-off, atomizing air control, flow control'
                    },
                    {
                        key: 'Atomozing Air',
                        value: 'compressed air= atomizing air, 5-10Nm³/h'
                    },
                    {
                        key: 'Max. Flow',
                        value: '190l/h Diesel/light oil (boosted up to 450l/h)'
                    },
                    {
                        key: 'Max. Pressure',
                        value: 'Diesel/Light Oil: 3 bar, Atomizing Air (compressed air): 3 bar'
                    },
                    {
                        key: 'Weight',
                        value: '12 kg'
                    },
                    {
                        key: 'Couplings',
                        value: 'Oil Safety Coupling ½” / Atomizing Air ¾”'
                    },
                ]
            },
            {
                listsTitle: 'Position 3	High velocity burner, type HVO3600 for oil',
                listsDescription: [
                    {
                        key: 'Safety components',
                        value: 'UV Flame Sensor, automatic ignition, pressure switch'
                    },
                    {
                        key: 'Power',
                        value: '190l/h Diesel/light oil = 1,9MW (boosted up to 450l/h = 4,6MW) '
                    },
                    {
                        key: 'Nozzle',
                        value: 'Refractpry Steel (1250°C) optional Water Cooled (1450°C)'
                    },
                    {
                        key: 'Dimensions of the nozzle',
                        value: '200x200mm (other dimensions on request)'
                    },
                    {
                        key: 'Air Inlet',
                        value: '250mm'
                    },
                    {
                        key: 'Fuel Inlet',
                        value: 'Oil Safety Coupling ½” / Atomizing Air ¾”'
                    },
                    {
                        key: 'Weight',
                        value: '22kg'
                    },
                ]
            }
        ],
        images: [
            {
                src: require('../../images/gallery/rhts/HVO3600.png'),
                nodeRef: createRef(null)
            },
        ],
    },
];