import React from 'react';
import { useHistory } from "react-router-dom";
import postWeldHT from "../../images/gallery/rhts/post-weld-heat-treatment.png";
import pipe from "../../images/gallery/rhts/pipe.jpg";
import bolt from "../../images/gallery/rhts/bolt-services.jpeg";
import { forwardRef } from 'react';

 const Services = forwardRef((props,ref) => {
    return (
        <div className="section-full bg-white content-inner" id="services" ref={ref}>
            <div className="container">
                <div className="section-head">
                    <h2 className="title text-center">Our Services</h2>
                    <p className='text-center'><b>HOTWORK HEAT TREATMENT & SPECIALIZED SERVICES</b>, LDA can help improve the integrity of weld surfaces, minimizing thermal stress induced during the welding process  utilizing thermal treatment, this is crucial to maximize the life of your vessels pressures, towers, tanks, equipment, and pipes involved in oil and gas processes, all of this is supported by an  engineering group with software for require heat and thermal calculations, band width, tower weight and wind load analysis and Zick analysis for horizontal vessels.</p>
                </div>
                <div className="section-content row">
                    <Service 
                        delay="0.2s"
                        duration="2s"
                        title="Postweld Heat Treatment"
                        description = "We have in our installations furnaces to  post weld heat treating of large vessel pressures or vessel sections, approved for temperatures from ambient up to 980°C."
                        id="postweld-ht"
                        images={postWeldHT}
                    />
                    <Service 
                        delay="0.8s"
                        duration="2s"
                        title="Bolt and torque services"
                        description="HOTWORK HEAT TREATMENT & SPECIALIZED SERVICES - supported by an engineering group with software for required heat and thermal calculations, band width, tower weight, wind load analysis and Zick analysis for horizontal vessels."
                        id="zl-anaylsis"
                        images={bolt}
                    />  
                    <Service 
                        delay="0.8s"
                        duration="2s"
                        title="Cut and cold pipes"
                        description="We count the hardness of any metal with the Hardness Tester NOVOTEST T-UD3. This combines two methods of measuring hardness by an indirect method: Ultrasonic Contact Impedance (ASTM A1038) and Leeb (ASTM A956).
                        The ability to connect both probes combine the advantages of both methods and providing users the opportunity to use the one that is most suitable for solving a specific task. The Combined Hardness Tester allows us to measure the hardness of any metals, parts of any shape and thickness, measure the hardness of the surface hardened layers and evaluate the tensile strength according to our clients’ requirements.
                        "
                        id="hardness-testing"
                        images={pipe}
                    />
                </div>
            </div>
        </div>
    );
});

export default Services;

const Service = ({delay, duration, title, description, count, images }) => {
    let history = useHistory();
    return (
        <div className="col-md-4  service-box style3 wow fadeInUp flex-parent" data-wow-duration={duration} data-wow-delay={delay}>
            <div className="icon-bx-wraper" style={{background: '#15141408'}}>
                <div className=''>
                    <div className="d-flex align-items-center">
                        <img src={images} style={{height: 80,width: 80, 
                            borderRadius: '50%', position: 'relative', left:'-10px'}} alt={title} />
                        <strong style={{fontSize:"1.3rem", whiteSpace:"pre"}}>{title}</strong>
                    </div>
                    <div className="">
                        <p className='text-justify'>{description.length > 100 ? description.slice(0, 100) + '...' : description}</p>
                        <div className='mt-auto'>
                            <button className="btn btn-primary services-button" onClick={() => {
                                history.push("/services");
                            }}>
                                View More 
                            </button>
                        </div>
                    </div>
                </div> 
                
            </div>    
        </div>
    );
}