import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { CategoryLists } from '../../../sections/servicesData';
import Check from '../../../../images/gallery/rhts/check.png';
import img1 from '../../../../images/gallery/rhts/hardnesstesting.jpg';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function NonDesctructiveLists(){
    const query = useQuery();
    const categoryId = query.get('service');

    useEffect(() => {
        if (query.get('service')) {

        }
    }, [query]);

    return(
        <>
            {
                categoryId === 'hardness-testing' ? <HardnessTesting /> :
                <Redirect to={`/services`} />
            }
        </>
    )
}

const HardnessTesting = (() => {
    return(
        <>
            {
                CategoryLists[4].list.map((el, key) => {
                    if (key === 0) {
                        return(
                            <div className='container services' style={{height:'950px'}}>
                                <h3>Hardness Testing</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img1})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})




