import React, {Component} from 'react';
import { useEffect } from 'react';
import {Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Sticky from 'react-stickynode';

const Header = React.forwardRef((props, ref) => {
    const buttonRef = React.useRef(null);
    const headerNavRef = React.useRef(null);
    
    useEffect(() => {
        if (buttonRef) {
            buttonRef.current.addEventListener('click', (e) => {
                buttonRef.current.classList.toggle("open");
                headerNavRef.current.classList.toggle("show");
            });
        }
    }, [buttonRef]);

    var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
    for (var y = 0; y < navUl.length; y++) {
        navUl[y].addEventListener('click', function () { checkLi(this) });
    }

    function checkLi(current) {
        const active = current.classList.contains("open")
        navUl.forEach(el => el.classList.remove('open'));
       
        if(active){
            current.classList.remove('open') 
            console.log("active")
        } else{
            current.classList.add('open');
            console.log("close")
        }
    }  
   return (
        <>
            <header className="site-header mo-left header-transparent overlay header navstyle4" ref={ref}>
                <Sticky innerZ={999} enabled={true}>
                    <div className="sticky-header main-bar-wraper navbar-expand-lg" expand="lg">
                        <div className="main-bar clearfix headerTarget">
                            <div className="container clearfix">
                                <div className="logo-header mostion">
                                    <a href="/"><img src={require("../../images/hhts-logo.png")} alt=""/></a>
                                </div>
                                <button ref={buttonRef} className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                                <div ref={headerNavRef} className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                    <div className="logo-header d-md-block d-lg-none">
                                        <a href='/'><img src={require("../../images/hhts-logo.png")} alt=""/></a>
                                    </div>
                                    <ul className="nav navbar-nav">	
                                        <li className="nav-item homedemo"> 
                                            <a href='/'>
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item"> 
                                            <a href="/services">
                                                Services
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href='/equipment'>Equipments</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href='/downloads'>Downloads</a>
                                        </li>
                                        {/* <li className="nav-item"> 
                                            <a href='/about-us'>About us</a>
                                        </li> */}
                                        <NavDropdown title="About Us" className='nav-item'>
                                            <NavDropdown.Item href='/about-us'>Company</NavDropdown.Item>
                                            <NavDropdown.Item href="/team">Meet the Team</NavDropdown.Item>
                                        </NavDropdown>
                                        <li className="nav-item">
                                            <a href="/#contact">Contact Us</a>
                                        </li>
                                    </ul>		
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
            </header>                
        </>
    )
})


export default Header;