import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { CategoryLists } from '../../../sections/servicesData';
import Check from '../../../../images/gallery/rhts/check.png';
import img1 from '../../../../images/gallery/rhts/pic8.jpg';
import img2 from '../../../../images/gallery/rhts/dehydrogenationHT.jpg';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CoatingLists(){
    const query = useQuery();
    const categoryId = query.get('service');

    useEffect(() => {
        if (query.get('service')) {

        }
    }, [query]);

    return(
        <>
            {
                categoryId === 'coating-and-lines-cures' ? <CoatingAndLines /> :
                categoryId === 'space-heating-for-coating-and-lining' ? <SpaceAndLining /> :
                <Redirect to={`/services`} />
            }
        </>
    )
}

const CoatingAndLines = (() => {
    return(
        <>
            {
                CategoryLists[2].list.map((el, key) => {
                    if (key === 0) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                <h3>Coating and Lines Cures</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img1})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const SpaceAndLining = (() => {
    return(
        <>
            {
                CategoryLists[2].list.map((el, key) => {
                    if (key === 1) {
                        return(
                            <div className='container services' style={{height:'950px'}}>
                                <h3>Space Heating for coating and Lining</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img2})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})
