import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { CategoryLists } from '../../../sections/servicesData';
import Check from '../../../../images/gallery/rhts/check.png';
import img1 from '../../../../images/gallery/rhts/pic8.jpg';
import img2 from '../../../../images/gallery/rhts/htAnnealing.jpg';
import img3 from '../../../../images/gallery/rhts/htInWarehouse.jpg';
import img4 from '../../../../images/gallery/rhts/htInFurnace.jpg';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function HeatTreatmentLists(){
    const query = useQuery();
    const categoryId = query.get('service');

    useEffect(() => {
        if (query.get('service')) {

        }
    }, [query]);

    return(
        <>
            {
                categoryId === 'post-weld-heat-treatment' ? <PostWeldHeatTreatment /> :
                categoryId === 'annealing-and-normalizing' ? <AnnealingAndNormalizing /> :
                categoryId === 'heat-treatment-in-house' ? <HeatTreatmentInHouse /> :
                categoryId === 'heat-treatment-in-furnaces-on-site' ? <HeatTreatmentTemporary /> :
                <Redirect to={`/services`} />
            }
        </>
    )
}

const PostWeldHeatTreatment = (() => {
    return(
        <>     
            {
                CategoryLists[0].list.map((el, key) => {
                    if (key === 0) {
                        return(
                            <div className='container services' style={{height: '1450px'}}>
                                <h3>Post Weld Heat Treatment</h3>
                                <div className='services-image-container' style={{ 
                                    backgroundImage:`url(${img1})`
                                }}>
                                   
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                                <div className='services-lists'>
                                    <div className='services-lists-container'>
                                        <div className='list'>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Improving the ductility of the materials</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Reducing the instances brittle fractures</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Tempered metal</p>
                                            </div>
                                        </div>
                                        <div className='list'>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Improves and in some cases reduces the metal’s hardness</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>Improve metallurgical properties</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>The prevention of cracking caused by hydrogen by its removal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const AnnealingAndNormalizing = (() => {
    return(
        <>
            {
                CategoryLists[0].list.map((el, key) => {
                    if (key === 1) {
                        return(
                            <div className='container services' style={{height:'950px'}}>
                                <h3>Annealing and Normalizing</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img2})`}}>
                   
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const HeatTreatmentInHouse = (() => {
    return(
        <>
            {
                CategoryLists[0].list.map((el, key) => {
                    if (key === 2) {
                        return(
                            <div className='container services' style={{height: '1050px'}}>
                                <h3>Heat treatment in House</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img3})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '15px'}}>{el.description}</p>
                                <div className='services-lists'>
                                    <div className='services-lists-container' style={{marginTop: '30px'}}>
                                        <div className='list'>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>L 9800 mm</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>W 3000 mm</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>H 1800 mm or 3400 mm</p>
                                            </div>
                                        </div>
                                        <div className='list'>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>L 5000 mm</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>L 5000 mm</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>H 2000mm</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const HeatTreatmentTemporary = (() => {
    return(
        <>
            {
                CategoryLists[0].list.map((el, key) => {
                    if (key === 3) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                 <h3>Heat Treatment Temporary Furnaces on Site</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img4})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})