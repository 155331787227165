import React, {useEffect, useRef, useState} from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { HeatTreatment } from './HeatTreatment';
import { PreHeating } from './PreHeating';
import { CoatingCures } from './CoatingCures';
import { Engineering } from './Engineering';
import { NonDesctructive } from './NonDesctructive';
import { MechanicalField } from './MechanicalField';
import ".../../../src/css/style.css";
import bgSmoke from "../../../images/background/smoke.png";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Category(){
    const query = useQuery();
    const categoryId = query.get('categoryLists');
    useEffect(() => {
        if (query.get('categoryLists')) {
            //const id = query.get('services');
        }
    }, [query]);

    return(
        <>
        <Header />
            <div className="section-full bg-gray content-inner position-relative"
            style={{ 
                backgroundImage:`url(${bgSmoke})`, 
                paddingBottom:'50px',
                backgroundSize:'cover'
            }}>
                {  
                    categoryId === 'heat-treatment' ? <HeatTreatment /> :
                    categoryId === 'pre-heating' ? <PreHeating /> :
                    categoryId === 'coating-cures' ? <CoatingCures /> :
                    categoryId === 'engineering' ? <Engineering /> :
                    categoryId === 'non-destructive' ? <NonDesctructive /> :
                    categoryId === 'mechanical-field-services' ? <MechanicalField /> 
                    : <Redirect to={`/services`} />
                    //console.log(categoryId)
                }                   
            </div>
        <Footer />
        </>
    )
}