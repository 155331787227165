import Team1 from '../element/team1'
import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

export default function Teams() {
    return (
        <>
            <Header />
                <div className="section-full bg-white content-inner" id="aboutUs" style={{height: '1350px'}}>
                    <div className="container" style={{marginTop: '110px'}}>
                        <div className="section-head text-center">
                            <h2 className="title">Meet The Team</h2>
                            <p>At the heart of our operations lie essential core values centered around people, technology, and productivity. Our unwavering commitment to these principles drives us to deliver the utmost level of service excellence to our valued customers.</p>
                        </div>
                        <Team1 />
                    </div>
                </div>
            <Footer />
        </>
    );
}