import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from './pages/Home';
import ServiceLists from './pages/ServiceLists';
import Equipment from './pages/Equipment';
import Mission from './pages/Mission';
import Download from './pages/Download';
import Team from './sections/Teams';
import EquipmentLists from './pages/equipment/equipmentLists';
import ServiceCategoryLists from './pages/category-services/CategoryLists';
import Service from './pages/theservice/Service';

export default function Router() {
    return (
            
        //<BrowserRouter>
        <BrowserRouter>
            <div className="page-wraper">
                <Switch> 
                    <Route path='/services' exact={true} component={ServiceLists} />
                    <Route path='/downloads' exact={true} component={Download} />
                    <Route path='/team' exact={true} component={Team} />
                    <Route path='/equipment' exact={true} component={Equipment} />
                    {/* <Route path='/equipment/:slug' exact={true} component={Equipment} /> */}
                    <Route path='/contact' exact={true} component={Mission} />

                    <Route path='/about-us' exact={true} component={Mission} />
                    <Route path='/' exact={true} component={HomePage} />
                    <Route path='/equipment/equipmentLists' exact={true} component={EquipmentLists} />
                    <Route path='/category-services/categoryLists' exact={true} component={ServiceCategoryLists} />
                    <Route path='/theservice/service' exact={true} component={Service} />
                </Switch>
            </div>

        </BrowserRouter>

    )
};