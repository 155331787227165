import React, {useEffect, useRef, useState} from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ScrollToTop from '../element/scrollToTop';
import { useHistory, useLocation } from "react-router-dom";
import "../../css/style.css";
import servicesData from '../sections/servicesData';
import {Nav} from "react-bootstrap";
import './services.css';
import { useCallback } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { CSSTransition } from 'react-transition-group';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import HeatTreatment from "../../images/gallery/rhts/pic8.jpg";
import PreHeating from "../../images/gallery/rhts/bake out.jpg";
import boltHeating from "../../images/gallery/rhts/boltHeating.jpg";
import Engineering from "../../images/gallery/rhts/zick.png";
import NonDestructive from "../../images/gallery/rhts/hardness testing.jpg";
import bgSmoke from "../../images/background/smoke.png";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

// export default function ServiceLists() {
//     const headerRef = React.useRef(null);

//     return (
//         <>
//             <Header useRef={headerRef} />
//             <Test />
//             <Footer />
//         </>
//     )
// }

// const Test = () => {
//     return(
//         <>
//          <div className="section-full bg-gray content-inner position-relative">
//             <div className="row d-flex justify-content-center p-5">
//                 <div className="col-md-8">
//                     <a href='/services/sub'>asda</a>
//                 </div>
//             </div>
//          </div>
//         </>
//     )
// }

// export default function ServiceLists() {
//     const history = useHistory();
//     const headerRef = React.useRef(null);
//     const [headerHeight, setHeaderHeight] = useState(null);
//     const query = useQuery();
//     const [isMobile, setIsMobile] = useState(false);
//     const [showSidebar, setShowSidebar] = useState(false);
//     const sidebarRef = React.useRef(null);

//     const onClickServices = (e, id) => {
//         e.preventDefault();
//         scrollView(id);
//         history.push('?services=' + id);
//     };

//     const scrollView = useCallback((id) => {
//         const { containerRef } = servicesData.find(item => item.id === id);
//         window.scroll({
//             top: containerRef.current.offsetTop,
//             behavior: 'smooth',
//         });
//         containerRef.current.style.border = '2px solid #e2211c';
//         containerRef.current.style.transition = '1s border';
//         setTimeout(() => {
//             containerRef.current.style.border = '2px solid transparent';
//         }, 1000);

//         setTimeout(() => {
//             setShowSidebar(false);
//         }, 400)
//     }, []);

//     useEffect(() => {
//         if (query.get('services')) {
//             const id = query.get('services');
//             scrollView(id);
//         }
//     }, [query, scrollView]);

//     useEffect(() => {
//         if (headerRef && headerRef.current) {
//             setHeaderHeight(headerRef.current.offsetHeight);
//         }
//     }, [headerRef]);

//     useEffect(() => {
//         const onResize = () => {
//             if (window.innerWidth <= 767) {
//                 setIsMobile(true);
//             } else {
//                 setIsMobile(false);
//             }
//         }
//         onResize();
//         window.addEventListener('resize', onResize);
//         return () => {
//             window.removeEventListener('resize', onResize);
//         }
//     }, []);

//     return (
//         <>
//         <Header ref={headerRef} />
//             <div className="section-full bg-gray content-inner position-relative">
//                 <div className='container mt-5'>
//                     <div className="row">
//                         {!isMobile && (
//                             <div className="col-md-4">
//                                 <Side top={headerHeight} 
//                                     onScroll={onClickServices}
//                                 />
//                             </div>
//                         )}
//                         <div className="col-md-8">
//                             {console.log(servicesData)}
//                             {servicesData && servicesData.map((service, key) => (
//                                 <ServicesSection
//                                     ref={service.containerRef}
//                                     key={key}
//                                     header={service.header}
//                                     id={service.id}
//                                     title={service.title}
//                                     description={service.description}
//                                     lists={service.lists}
//                                     images={service.images.length > 0 ? service.images[0].src :null}
//                                     color={key % 2 ? '#e8e9eb' : 'red'}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 </div>


//                 {isMobile && (
//                     <>
//                         <button className='toggle-services-sidebar' onClick={() => setShowSidebar(true)}>
//                             <IoEllipsisHorizontal size={70}/>
//                         </button>

//                         <CSSTransition
//                             in={showSidebar}
//                             nodeRef={sidebarRef}
//                             timeout={300}
//                             classNames="services-sidebar"
//                             unmountOnExit
//                         >
//                             <div className='menu-overlay' ref={sidebarRef}>
//                                 <div className="services-menu-overlay">
//                                     <div className="container-header d-flex align-items-center p-2">
//                                         <button onClick={() => setShowSidebar(false)}>&times;</button>
//                                         <h6 className='m-0 ml-2'>Menu</h6>
//                                     </div>
//                                     <Side 
//                                         top={headerHeight} 
//                                         onScroll={onClickServices} 
//                                         sticky={false}
//                                     />
//                                 </div>
//                             </div>
//                         </CSSTransition>
//                     </>
//                 )}

//             </div>
//         <Footer />
//         <ScrollToTop className="style3"/>  
//         </>
//     );
// }

// const ServicesSection = React.forwardRef(({id, title, description, images, color, lists, header}, ref) => {
//     return (
//         <>
//             <div className="services-section" id={id} color={color} ref={ref}>
//                 <div className="d-flex">
//                     <div className={images ? 'col-md-8' : 'col-md-12'}>
//                         <h5 class="services-ttl">{title}</h5>
//                         {description && description.map((desc, key) => (
//                             <>
//                                 {id == 'postweld-ht' && key >= 1 ?  (
//                                     <p className='mb-0 text-justify' style={{ width: '725px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{desc.value}</p>
//                                 ) : <p className='mb-0 text-justify'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{desc.value}</p> }
                                
//                                 {header ? <><div style={{ fontSize: '1rem' }}>{header}</div> <br /></>: null}
                                
//                                 {id == 'postweld-ht' && key === 3 ?  (
//                                 lists.map(value => (
//                                     <Lists>
//                                         {value}
//                                     </Lists>
//                                 ))
                                
//                                 ) : null}
//                                 {id == 'pre-heating' ?  (
//                                 lists.map(value => (
//                                     <Lists>
//                                         {value}
//                                     </Lists>
//                                 ))
                                
//                                 ) : null}
//                                 {id == 'ht-warehouse-furnaces' ?  (
//                                 lists.map(value => (
//                                     <>
//                                         <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>{value.title}</div>
//                                         {value.details.map(item => 
//                                             <Lists>
//                                                 {item}
//                                             </Lists>
//                                         )}  
                                        
                                           
//                                     </>
//                                 ))
                                
//                                 ) : null}
//                                 <br />
//                             </>
//                         ))}
//                     </div>
//                     {images && (
//                         <div className="col-md-4">
//                             <img className='service-image mb-2' src={images} alt={title} />
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </>     
//     );
// });

export default function Services() {
    const history = useHistory();
    const headerRef = React.useRef(null);
    const [headerHeight, setHeaderHeight] = useState(null);
    const query = useQuery();
    const [isMobile, setIsMobile] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const sidebarRef = React.useRef(null);

    const onClickServices = (e, id) => {
        e.preventDefault();
        scrollView(id);
        history.push('?services=' + id);
    };

    const scrollView = useCallback((id) => {
        const { containerRef } = servicesData.find(item => item.id === id);
        window.scroll({
            top: containerRef.current.offsetTop,
            behavior: 'smooth',
        });
        containerRef.current.style.border = '2px solid #e2211c';
        containerRef.current.style.transition = '1s border';
        setTimeout(() => {
            containerRef.current.style.border = '2px solid transparent';
        }, 1000);

        setTimeout(() => {
            setShowSidebar(false);
        }, 400)
    }, []);

    useEffect(() => {
        if (query.get('services')) {
            const id = query.get('services');
            scrollView(id);
        }
        console.log(servicesData);
    }, [query, scrollView]);

    useEffect(() => {
        if (headerRef && headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    }, [headerRef]);

    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth <= 767) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    return (
        <>
            <Header />
                <div className="section-full bg-gray content-inner position-relative" 
                style={{ 
                    backgroundImage:`url(${bgSmoke})`,
                    backgroundSize:'cover'
                }}>
                    <div className='container services' style={{height: '820px'}}>
                        <h1>Services</h1>
                        <div className='services-container'>
                        <Service 
                            title="Heat Treatment"
                            description ="Heat treatment is a carefully controlled procedure that involves heating and cooling metals or alloys with the purpose of modifying their physical and, on occasion, chemical attributes."
                            id="heat-treatment"
                            images={HeatTreatment}
                        />
                        <Service 
                            title="Preheating"
                            description ="Advanced control systems and record preheating during the welding process. This minimizes thermal efforts mainly in these three ways, firstly this reduces humidity released by hydrogen which could penetrate the welded steel causing porous spots and cracks right after. Second, it reduces heat loss surrounding the welded surfaces making the weld stronger, and finally improves the microstructure and durability of the zone affected by heat."
                            id="pre-heating"
                            images={PreHeating}
                        />
                        {/* <Service 
                            title="Preheating"
                            description ="Heating a material before welding or other manufacturing operations to reduce stress, prevent cracking, and improve weld quality by creating a more controlled thermal environment. "
                            id="pre-heating"
                            images={PreHeating}
                        /> */}
                        <Service 
                            title="Coating Cures"
                            description ="Coating cures is the process of allowing applied coatings to fully dry and harden. It ensures durability."
                            id="coating-cures"
                            images={boltHeating}
                        />
                        <Service 
                            title="Engineering"
                            description ="We carefully apply important engineering methods that we develop to design custom solutions for your company."
                            id="engineering"
                            images={Engineering}
                        />
                        <Service 
                            title="Non-Destructive Services"
                            description ="Inspecting materials and structures without causing damage, identifying defects, and ensuring safety. Our methods include ultrasonic testing, radiography, and visual inspection."
                            id="non-destructive"
                            images={NonDestructive}
                        />
                        <Service 
                            title="Mechanical field Services"
                            description ="On-site maintenance and repair by skilled technicians to ensure the efficient functioning of mechanical equipment in industries like manufacturing, construction, power generation, and transportation."
                            id="mechanical-field-services"
                            images={HeatTreatment}
                        />
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    );
}

const Service = ({title, description, id, images}) => {
    let history = useHistory();
    return(
        <>
            <div className='box' id={id}>
                <span></span><span></span><span></span><span></span>
                <div className='sc-header'>
                    <img src={images} style={{height: 80,width: 80, borderRadius: 40}} alt={title} />
                    <strong className='services-ttl'>{title}</strong>
                </div><hr />
                <div className='sc-body'>
                    <p style={{marginTop: '15px'}}>{description.length > 110 ? description.slice(0, 110) + '...' : description}</p>
                        <button className="btn btn-primary services-btn" onClick={() => {
                            history.push("/category-services/categoryLists?categoryLists=" + id);
                        }}>
                            View More
                        </button>
                </div>
            </div>
        </>
    )
}