import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { CategoryLists } from '../../../sections/servicesData';
import Check from '../../../../images/gallery/rhts/check.png';
import img1 from '../../../../images/gallery/rhts/bakeout.jpg';
import img2 from '../../../../images/gallery/rhts/dehydrogenationHT.jpg';
import img3 from '../../../../images/gallery/rhts/boltHeating.jpg';
import turbine from '../../../../images/gallery/rhts/turbine.jpg';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PreHeatingLists(){
    const query = useQuery();
    const categoryId = query.get('service');

    useEffect(() => {
        if (query.get('service')) {

        }
    }, [query]);

    return(
        <>
            {
                categoryId === 'hydrogen-bake-out' ? <HydrogenBakeOut /> :
                categoryId === 'welds-preheating' ? <WeldsPreheating /> :
                categoryId === 'dehydrogenation-heat-treatment' ? <DehydrogenationHeat /> :
                categoryId === 'heat-shrink' ? <HeatShrink /> :
                categoryId === 'turbine-warming-systems' ? <TurbineWarming /> :
                <Redirect to={`/services`} />
            }
        </>
    )
}

const HydrogenBakeOut = (() => {
    return(
        <>
            {
                CategoryLists[1].list.map((el, key) => {
                    if (key === 0) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                <h3>Hydrogen Bake out</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img1})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                                {/* <div className='services-lists'>
                                    <div className='services-lists-container'>
                                        <div className='list'>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Improving the ductility of the materials</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Reducing the instances brittle fractures</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Tempered metal</p>
                                            </div>
                                        </div>
                                        <div className='list'>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>Improves and in some cases reduces the metal’s hardness</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} style={{height:'25px', width: '25px'}} /><p>Improve metallurgical properties</p>
                                            </div>
                                            <div className='list-body'>
                                                <img src={Check} alt="" style={{height:'25px', width: '25px'}} /><p>The prevention of cracking caused by hydrogen by its removal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const WeldsPreheating = (() => {
    return(
        <>
            {
                CategoryLists[1].list.map((el, key) => {
                    if (key === 1) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                <h3>{el.name}</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img2})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const DehydrogenationHeat = (() => {
    return(
        <>
            {
                CategoryLists[1].list.map((el, key) => {
                    if (key === 2) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                <h3>{el.name}</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img2})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '50px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const HeatShrink = (() => {
    return(
        <>
            {
                CategoryLists[1].list.map((el, key) => {
                    if (key === 3) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                 <h3>{el.name}</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${img3})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})

const TurbineWarming = (() => {
    return(
        <>
            {
                CategoryLists[1].list.map((el, key) => {
                    if (key === 4) {
                        return(
                            <div className='container services' style={{height: '950px'}}>
                                <h3>{el.name}</h3>
                                <div className='services-image-container' style={{ backgroundImage:`url(${turbine})`}}>
                                    
                                </div>
                                <p style={{textAlign: 'justify', marginTop: '30px'}}>{el.description}</p>
                            </div>
                        );
                    }
                })
            }
        </>
    )
})