import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import ".../../../src/css/style.css";
import HeatTreatmentLists from './heat-treatment/Lists';
import PreHeatingLists from './pre-heating/Lists';
import CoatingLists from './coating-cures/Lists';
import EgineeringLists from './engineering/Lists';
import NonDesctructiveLists from './non-destructive/Lists';
import MechanicalLists from './mechanical-field/Lists';
import bgSmoke from '../../../images/background/smoke.png';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Service(){
    const query = useQuery();
    const categoryId = query.get('service');

    useEffect(() => {
        if (query.get('service')) {

        }
    }, [query]);

    const height = categoryId === 'annealing-and-normalizing' ? 900 : categoryId === 'heat-treatment-in-furnaces-on-site' ? 900 :
    categoryId === 'hydrogen-bake-out' ? 950 : categoryId === 'welds-preheating' ? 930 : categoryId === 'dehydrogenation-heat-treatment' ? 930 : 
    categoryId === 'heat-shrink' ? 930 : categoryId === 'turbine-warming-systems' ? 930 : categoryId === 'coating-and-lines-cures' ? 930 :
    categoryId === 'space-heating-for-coating-and-lining' ? 930 : categoryId === 'zick-and-load-analysis' ? 930 :
    categoryId === 'heat-treatment-procedures' ? 930 : categoryId === 'bolt-and-torquing-procedures' ? 930 : categoryId === 'hardness-testing' ? 930 : 
    categoryId === 'bolt-services' ? 930 : categoryId === 'torque-services' ? 930 : categoryId === 'pipe-cutting' ? 930 :
    categoryId === 'weld-prep-and-bevelling' ? 930 : '';

    return(
        <>
            <Header />
                <div className="section-full bg-gray content-inner position-relative"
                style={{ 
                    backgroundImage:`url(${bgSmoke})`, 
                    //height:height,
                    backgroundSize:'cover'
                }}>
                        {
                            categoryId === 'post-weld-heat-treatment' ? <HeatTreatmentLists /> :
                            categoryId === 'annealing-and-normalizing' ? <HeatTreatmentLists /> :
                            categoryId === 'heat-treatment-in-house' ? <HeatTreatmentLists /> :
                            categoryId === 'heat-treatment-in-furnaces-on-site' ? <HeatTreatmentLists /> :
                            categoryId === 'hydrogen-bake-out' ? <PreHeatingLists /> :
                            categoryId === 'welds-preheating' ? <PreHeatingLists /> :
                            categoryId === 'dehydrogenation-heat-treatment' ? <PreHeatingLists /> :
                            categoryId === 'heat-shrink' ? <PreHeatingLists /> :
                            categoryId === 'turbine-warming-systems' ? <PreHeatingLists /> :
                            categoryId === 'coating-and-lines-cures' ? <CoatingLists /> :
                            categoryId === 'space-heating-for-coating-and-lining' ? <CoatingLists /> :
                            categoryId === 'zick-and-load-analysis' ? <EgineeringLists /> :
                            categoryId === 'heat-treatment-procedures' ? <EgineeringLists /> :
                            categoryId === 'bolt-and-torquing-procedures' ? <EgineeringLists /> :
                            categoryId === 'hardness-testing' ? <NonDesctructiveLists /> :
                            categoryId === 'bolt-services' ? <MechanicalLists /> :
                            categoryId === 'torque-services' ? <MechanicalLists /> :
                            categoryId === 'pipe-cutting' ? <MechanicalLists /> :
                            
                            <Redirect to={`/services`} />
                        }
                </div>
            <Footer />
        </>
    )
}