import React from 'react';
import { Link } from 'react-router-dom';
//import Slider from "react-slick";


export default function Team1() {

	return (
		<>
			<div className="row justify-content-center">
				{teams && teams.map((team, key) => (
					<TeamMember
						key={key}
						{...team}
					/>
				))}
			</div>
		</>
	)
}

const teams = [
	{
		name: 'João Ribeiro',
		position: 'General Director',
		image: require("../../images/our-team/Joao.png"),
        email: 'joao.ribeiro@hotworkhts.com'
	},
	{
		name: 'Mirna Morales',
		position: 'Accounting',
		image: require("../../images/our-team/mirna.png"),
        email: 'mirna.morales@hotworkhts.com'
	},
	{
		name: 'Dany Reyes',
		position: 'Technical Sales',
		image: require("../../images/our-team/dany.png"),
        email: 'dany.reyes@hotworkhts.com'
	},
    {
		name: 'Alexandra Torres Reyes',
		position: 'Operations Manager',
		image: require("../../images/our-team/alexandra.png"),
        email: ' Alexandra.reyes@hotworkhts.com'
	},
    {
		name: 'Alberto Reyes',
		position: 'Project Manager',
		image: require("../../images/our-team/alberto.png"),
        email: 'Alberto.reyes@hotworkhts.com'
	},
    {
		name: 'Paola Palacios',
		position: 'Accounting',
		image: require("../../images/our-team/default-female.jpg"),
        email: 'paola.palacios@hotworkhts.com'
	},
];

function TeamMember({ name, position, image,email }) {
	return (
		<div className="col-lg-4 col-md-6 col-sm-6">
			<div className="dlab-box m-b30 dlab-team1">
				<div className="dlab-box dlab-team1">
					<div className="inner">
						<div className="dlab-media">
							<Link to={"#"}>
								<img className="w-100" style={{height: '400px'}} src={image} alt=''/>
							</Link>
						</div>
						<div className="dlab-info">
							<h4 className="dlab-title"><Link to={"#"}>{name}</Link></h4>
							<span className="dlab-position">{position}</span>
                            <p><a href="#" style={{textDecoration: 'none', color: '#232323'}}>{email}</a></p>
							<ul className="dlab-social-icon dez-border">
								<li><Link to={"#"} className="fa fa-facebook"></Link></li>
								<li><Link to={"#"} className="fa fa-twitter"></Link></li>
								<li><Link to={"#"} className="fa fa-linkedin"></Link></li>
								<li><Link to={"#"} className="fa fa-pinterest"></Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

