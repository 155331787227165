import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import downloadData from '../sections/downloadData';
import Pdf from '../../documents/HHTSS Brochure.pdf';
import Bolt from '../../documents/Bolting Service.pdf';
import Pipe from '../../documents/Pipe cutting.pdf';

export default function Download() {
  return (
    <>
      <Header />
            <div className="section-full bg-white content-inner">
                {/* <div className='download-header' style={{ backgroundImage: `url(${require('../../images/banner/download-header-banner.png')})`, backgroundSize: '100% 300px' }}>
                    <div className='container dh-header'>
                        <h3>DOWNLOADS</h3>
                    </div>
                </div> */}
                    <div className="container dh-content" style={{height:'880px'}}>
                        <h3>Information Flyers</h3>
                        <div className="row downloads">
                            { downloadData && downloadData.company.map((data, key) => (
                                    
                                <ContentSection 
                                title={data.title}
                                image={data.image}
                                pdf={data.title == 'Brochure' ? Pdf : '' || 
                                data.title == 'Bolting Service' ? Bolt : '' || 
                                data.title == 'Pipe Cutting' ? Pipe : ''}
                                />

                            ))}
                        </div>  
                        <br />
                        <p>
                            To browse more of our services, please check it here: <a href="/services" style={{color: 'red'}}>HTS Services</a>
                        </p>
                    </div>
            </div>
        <Footer />    
    </>
  );
};

const ContentSection = React.forwardRef(({ title, image, pdf}, ref) => {
    return (
        <>
            {/* <div className='dh-content-section' ref={ref}>
                <Card style={{ width: '25rem', height: '35rem' }}>
                    <Card.Img variant="top" className='dh-image' src={ image } />
                    <Card.Body>
                        <Card.Title></Card.Title>
                        <a class="btn btn-primary" href={ pdf } target = "_blank">Download Now!</a>
                    </Card.Body>
                </Card>
            </div> */}
            <div className='dl-container' ref={ref} style={{ 
                                    backgroundImage:`url(${image})`,
                                }}>
                    <div className='dl-body-white-bg'>
                        <h4>{ title }</h4>
                        <a class="btn btn-primary" href={ pdf } target = "_blank">Download Now!</a>
                    </div>
                    
            </div>
        </>
    );
});